import React from "react";
import { Button, ButtonProps } from "@mui/material";

interface FilledButtonProps {
  children: React.ReactNode;
  color?: ButtonProps["color"];
  onClick: () => void;
}
const FilledButton = ({
  onClick,
  children,
  color = "primary",
}: FilledButtonProps) => {
  return (
    <Button
      variant="contained"
      color={color}
      disableElevation
      sx={{
        textTransform: "none",
        fontSize: "16px",
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default FilledButton;
