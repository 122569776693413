import React from "react";
import Navbar from "./Navbar";
import { useMediaQuery, useTheme } from "@mui/material";
import DrawerComponent from "./Drawer";
import Footer from "./Footer";
export const Layout = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div>
      {isMobile ? <DrawerComponent /> : <Navbar />}
      {children}
      <Footer />
    </div>
  );
};
