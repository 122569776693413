import { Box, Grid, Typography } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { Link as MuiLink, Button } from "@mui/material";
import { Link } from "gatsby";
import React from "react";
import FilledButton from "../library/FilledButton";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import YouTubeIcon from "@mui/icons-material/YouTube";

const Footer = () => {
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        padding: { md: "50px 100px", sm: "50px 30px", xs: "50px 50px" },
      })}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={4}
              display="flex"
              flexDirection="column"
              gap="10px"
              justifyContent={{ md: "flex-end", xs: "center" }}
            >
              <Box>
                <StaticImage src="../../assets/logo.svg" alt="logo" />
              </Box>
              <Box display="flex" gap="5px">
                <a
                  href="https://www.facebook.com/markazdarulbir1"
                  style={{ cursor: "pointer", color: "unset" }}
                >
                  <FacebookIcon />
                </a>
                <a
                  href="https://twitter.com/markazdarulbir"
                  style={{ cursor: "pointer", color: "unset" }}
                >
                  <XIcon />
                </a>
                <a
                  href="https://www.youtube.com/@markazdarulbir"
                  style={{ cursor: "pointer", color: "unset" }}
                >
                  <YouTubeIcon />
                </a>
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="h3"
                component="h3"
                color="#fff"
                fontWeight="600"
              >
                Address:
              </Typography>
              <Typography variant="body1" color="#fff" fontSize="14px">
                Markaz Darulbir, Ahmed Nagar, Pedana, Krishna District Andhra
                Pradesh – 521366 (
                <a
                  style={{ color: "White" }}
                  href="https://maps.app.goo.gl/uiB5qBQZsbP2x1oL8"
                >
                  Google Location
                </a>
                )
              </Typography>

              <Typography
                variant="h3"
                component="h3"
                color="#fff"
                fontWeight="600"
                marginTop="5vh"
              >
                Phone Number:
              </Typography>
              <Typography variant="body1" color="#fff" fontSize="14px">
                +91 9912193013
              </Typography>
              <Typography variant="body1" color="#fff" fontSize="14px">
                +91 9010653013
              </Typography>
              <Typography variant="body1" color="#fff" fontSize="14px">
                +91 9177009564
              </Typography>

              <Typography
                variant="h3"
                component="h3"
                color="#fff"
                fontWeight="600"
                marginTop="5vh"
              >
                Email:
              </Typography>
              <Typography variant="body1" color="#fff" fontSize="14px">
                Markazdarulbir@gmail.com
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2} height={"105%"}>
            <Grid item xs={6}>
              <Typography
                variant="h3"
                component="h3"
                color="#fff"
                fontWeight="600"
              >
                Know more:
              </Typography>
              <Box
                sx={{
                  marginTop: "4vh",
                }}
              >
                <MuiLink
                  component={Link}
                  to="/"
                  sx={{
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  <Typography
                    variant="body1"
                    color="#fff"
                    fontSize="14px"
                    marginBottom="2vh"
                  >
                    Home
                  </Typography>
                </MuiLink>
                <MuiLink
                  component={Link}
                  to="/about"
                  sx={{
                    textDecoration: "none",
                    cursor: "pointer",
                    marginTop: "2vh",
                  }}
                >
                  <Typography
                    variant="body1"
                    color="#fff"
                    fontSize="14px"
                    marginBottom="2vh"
                  >
                    About Us
                  </Typography>
                </MuiLink>
                <MuiLink
                  component={Link}
                  to="/academics"
                  sx={{
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  <Typography
                    variant="body1"
                    color="#fff"
                    fontSize="14px"
                    marginBottom="2vh"
                  >
                    Academics
                  </Typography>
                </MuiLink>
                <MuiLink
                  component={Link}
                  to="/about#admissions"
                  sx={{
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  <Typography
                    variant="body1"
                    color="#fff"
                    fontSize="14px"
                    marginBottom="2vh"
                  >
                    Admissions
                  </Typography>
                </MuiLink>
                <MuiLink
                  component={Link}
                  to="/about#contact"
                  sx={{
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  <Typography
                    variant="body1"
                    color="#fff"
                    fontSize="14px"
                    marginBottom="2vh"
                  >
                    Contact Us
                  </Typography>
                </MuiLink>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              display="flex"
              justifyContent={{ md: "flex-end", xs: "center" }}
              alignItems={{ md: "flex-end", xs: "center" }}
            >
              <MuiLink
                component={Link}
                to="/about#donate"
                sx={{
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                <Button
                  variant="contained"
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  sx={(theme) => ({
                    textTransform: "none",
                    fontSize: "16px",
                    backgroundColor: "#fff",
                    color: theme.palette.primary.main,
                    ":hover": {
                      backgroundColor: "#fff",
                      color: theme.palette.primary.main,
                    },
                  })}
                  onClick={() => {}}
                >
                  Donate
                </Button>
              </MuiLink>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Footer;
