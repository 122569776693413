import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import { Link as MuiLink } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Link } from "gatsby";
import FilledButton from "../library/FilledButton";

function DrawerComponent() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openAcademicsDropdown, setOpenAcademicsDropdown] = useState(false);
  return (
    <>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        PaperProps={{
          sx: {
            width: "40%",
          },
        }}
      >
        <List>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <MuiLink
                component={Link}
                to="/"
                sx={{
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                <Typography variant="h3" component="h2" color={"secondary"}>
                  Home
                </Typography>
              </MuiLink>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <MuiLink
                component={Link}
                to="/about"
                sx={{
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                <Typography variant="h3" component="h2" color={"secondary"}>
                  About us
                </Typography>
              </MuiLink>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Typography
                variant="h3"
                component="h2"
                color={"secondary"}
                sx={{ display: "flex", alignItems: "center" }}
                onClick={() => setOpenAcademicsDropdown(!openAcademicsDropdown)}
              >
                Academics{" "}
                {openAcademicsDropdown ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </Typography>
              <List sx={{ display: openAcademicsDropdown ? "block" : "none" }}>
                <ListItem onClick={() => setOpenDrawer(false)}>
                  <ListItemText>
                    <MuiLink
                      component={Link}
                      to="/academics#arabic-college"
                      sx={{
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        variant="h4"
                        component="h2"
                        color={"secondary"}
                      >
                        Arabic College
                      </Typography>
                    </MuiLink>
                  </ListItemText>
                </ListItem>
                <ListItem onClick={() => setOpenDrawer(false)}>
                  <ListItemText>
                    <MuiLink
                      component={Link}
                      to="/academics#hifz-school"
                      sx={{
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        variant="h4"
                        component="h2"
                        color={"secondary"}
                      >
                        Hifz School
                      </Typography>
                    </MuiLink>
                  </ListItemText>
                </ListItem>
                <ListItem onClick={() => setOpenDrawer(false)}>
                  <ListItemText>
                    <MuiLink
                      component={Link}
                      to="/academics#al-bir-school"
                      sx={{
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        variant="h4"
                        component="h2"
                        color={"secondary"}
                      >
                        Al-Bir School
                      </Typography>
                    </MuiLink>
                  </ListItemText>
                </ListItem>
                <ListItem onClick={() => setOpenDrawer(false)}>
                  <ListItemText>
                    <MuiLink
                      component={Link}
                      to="/academics#books"
                      sx={{
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        variant="h4"
                        component="h2"
                        color={"secondary"}
                      >
                        Books
                      </Typography>
                    </MuiLink>
                  </ListItemText>
                </ListItem>
              </List>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <MuiLink
                component={Link}
                to="/about#admissions"
                sx={{
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                <Typography variant="h3" component="h2" color={"secondary"}>
                  Admissions
                </Typography>
              </MuiLink>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <MuiLink
                component={Link}
                to="/about#contact"
                sx={{
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                <Typography variant="h3" component="h2" color={"secondary"}>
                  Contact us
                </Typography>
              </MuiLink>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <MuiLink
                component={Link}
                to="/about#donate"
                sx={{
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                <FilledButton onClick={() => {}}>Donate</FilledButton>
              </MuiLink>
            </ListItemText>
          </ListItem>
        </List>
      </Drawer>
      <Box
        sx={{
          display: "flex",
          padding: "20px",
        }}
      >
        <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
          <MenuIcon />
        </IconButton>
        <Box
          sx={{
            flexGrow: "1",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h2">Markaz Darulbir</Typography>
        </Box>
      </Box>
    </>
  );
}
export default DrawerComponent;
