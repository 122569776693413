import red from "@mui/material/colors/red";
import { createTheme } from "@mui/material";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: `#2A5276`,
      light: `#DFE5EA`,
    },
    secondary: {
      main: `#333333`,
    },
    error: {
      main: red.A400,
    },
    background: {
      default: `#fff`,
    },
  },
  typography: {
    fontFamily: ["Open Sans", "sans-serif"].join(","),
    h1: {
      fontSize: "40px",
      fontWeight: 500,
      color: `#333333`,
    },
    h2: {
      fontSize: "24px",
      fontWeight: 500,
    },
    h3: {
      fontSize: "16px",
      fontWeight: 500,
    },
    h4: {
      fontSize: "14px",
    },
    body1: {
      fontSize: "16px",
      color: `#333333`,
    },
  },
});

export default theme;
