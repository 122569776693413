import React, { useState } from "react";
import { AppBar, Typography, Theme, Box } from "@mui/material";
import { Link as MuiLink } from "@mui/material";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import FilledButton from "../library/FilledButton";
import { useLocation } from "@reach/router";

const linkStyle = (theme: Theme) =>
  ({
    textDecoration: "none",
    color: "red",
    fontSize: "20px",
    marginLeft: theme.spacing(20),
    "&:hover": {
      color: "yellow",
      borderBottom: "1px solid white",
    },
  } as const);
function Navbar() {
  const [openAcademicsDropdown, setOpenAcademicsDropdown] = useState(false);

  return (
    <AppBar
      component="nav"
      position="static"
      sx={{
        boxShadow: "none",
      }}
    >
      <Box
        sx={{
          backgroundColor: "white",
          paddingLeft: "100px",
          paddingRight: "100px",
          paddingTop: "16px",
          paddingBottom: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "12%",
              alignItems: "center",
            }}
          >
            <Link to="/">
              <StaticImage
                quality={100}
                src="../../assets/logo.png"
                alt="Markaz Darulbir"
              />
            </Link>
            {/* <img src="./logo.png" alt="Markaz Darulbir" /> */}
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "70%",
              gap: "5vw",
              alignItems: "center",
            }}
          >
            <MuiLink
              component={Link}
              to="/about"
              sx={{
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              <Typography variant="h3" component="h2" color={"secondary"}>
                About us
              </Typography>
            </MuiLink>
            <Box
              sx={{
                display: "flex",
                gap: "5px",
                cursor: "pointer",
                alignItems: "center",
                position: "relative",
              }}
              onMouseEnter={() => setOpenAcademicsDropdown(true)}
              onMouseLeave={() => setOpenAcademicsDropdown(false)}
            >
              <Typography variant="h3" component="h2" color={"secondary"}>
                Academics
              </Typography>
              <img
                width="10px"
                height="10px"
                src="./down-chevron.svg"
                alt="down-chevron"
              />
              <Box
                sx={(theme) => ({
                  width: "130px",
                  position: "absolute",
                  top: "80%",
                  transform: "translateY(5px)",
                  left: "0",
                  backgroundColor: "white",
                  display: openAcademicsDropdown ? "flex" : "none",
                  border: `1px solid ${theme.palette.secondary.main}`,
                  flexDirection: "column",
                  zIndex: 100,
                  borderRadius: "5px",
                })}
              >
                <Box
                  sx={(theme) => ({
                    borderBottom: `1px solid ${theme.palette.primary.main}`,
                    padding: "10px",
                    ":hover": {
                      backgroundColor: `rgba(42, 82, 118, 0.2)`,
                    },
                  })}
                >
                  <MuiLink
                    component={Link}
                    to="/academics#arabic-college"
                    sx={{
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    <Typography variant="h4" component="h2" color={"secondary"}>
                      Arabic College
                    </Typography>
                  </MuiLink>
                </Box>
                <Box
                  sx={(theme) => ({
                    borderBottom: `1px solid ${theme.palette.primary.main}`,
                    padding: "10px",
                    ":hover": {
                      backgroundColor: `rgba(42, 82, 118, 0.2)`,
                    },
                  })}
                >
                  <MuiLink
                    component={Link}
                    to="/academics#hifz-school"
                    sx={{
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    <Typography variant="h4" component="h2" color={"secondary"}>
                      Hifz School
                    </Typography>
                  </MuiLink>
                </Box>
                <Box
                  sx={(theme) => ({
                    borderBottom: `1px solid ${theme.palette.primary.main}`,
                    padding: "10px",
                    ":hover": {
                      backgroundColor: `rgba(42, 82, 118, 0.2)`,
                    },
                  })}
                >
                  <MuiLink
                    component={Link}
                    to="/academics#al-bir-school"
                    sx={{
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    <Typography variant="h4" component="h2" color={"secondary"}>
                      Al-Bir School
                    </Typography>
                  </MuiLink>
                </Box>
                <Box
                  sx={{
                    padding: "10px",
                    ":hover": {
                      backgroundColor: `rgba(42, 82, 118, 0.2)`,
                    },
                  }}
                >
                  <MuiLink
                    component={Link}
                    to="/academics#books"
                    sx={{
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    <Typography variant="h4" component="h2" color={"secondary"}>
                      Books
                    </Typography>
                  </MuiLink>
                </Box>
              </Box>
            </Box>
            <MuiLink
              component={Link}
              to="/about/#admissions"
              sx={{
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              <Typography variant="h3" component="h2" color={"secondary"}>
                Admissions
              </Typography>
            </MuiLink>
            <MuiLink
              component={Link}
              to="/about/#contact"
              sx={{
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              <Typography variant="h3" component="h2" color={"secondary"}>
                Contact Us
              </Typography>
            </MuiLink>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "20%",
              justifyContent: "flex-end",
            }}
          >
            <MuiLink
              component={Link}
              to="/about#donate"
              sx={{
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              <FilledButton onClick={() => {}}>Donate</FilledButton>
            </MuiLink>
          </Box>
        </Box>
      </Box>
    </AppBar>
  );
}
export default Navbar;
